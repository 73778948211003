<template>
  <b-card
    title="Collection"
    img-src="https://picsum.photos/600/300/?image=25"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem;"
    class="mb-2"
  >
    <b-card-text v-if="work_group.name">
      View {{ work_group.name }} 
    </b-card-text>
    <b-row>
      <b-col>
    <b-button 
      @click="navigateToWorkGroupCollection"
      variant="primary"
    >See Image xRef</b-button>
      </b-col>
      <!-- <b-col>
    <b-button 
      @click="navigateToAssociationCollection"
      variant="primary"
    >Go See Associations</b-button>
      </b-col> -->
    </b-row>
  </b-card>
</template>      

<script>
export default {
  props: {
    work_group: {
      Type: Object
    }
  },
  methods: {
    navigateToWorkGroupCollection() {
      let nav = {
        name: 'CollectionScreen', 
        params: {
          id: this.work_group['collection-id']
        }
      }
      this.$store.dispatch('query_path/follow_trail', {
        name: this.work_group.name + ' Collection',
        navigation: nav
      })
      this.$router.push(nav)
    },
    navigateToAssociationCollection() {
      let nav = {
        name: 'ItemImageAssociationScreen', 
        params: {
          collection_id: this.work_group['collection-id']
        }
      }
      this.$store.dispatch('query_path/follow_trail', {
        name: this.work_group.name + ' Collection',
        navigation: nav
      })
      this.$router.push(nav)
    }
  }
}
</script>
