<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col md="8">
        <work-group-update :id="id" @gotAttrs="assignAttrs"/>
      </b-col>
      <b-col md="4">
        <b-card bg-variant="light">
          <b-card-group deck>
            <collection-card
              :work_group="attrs"
            >
            </collection-card>
          </b-card-group>
        </b-card>
      </b-col>
    </b-row>
    <br>
    <actor-role-index
      :filtering="filtering"
      :refresh="refresh"
      @refreshed="refreshed"
    >
    </actor-role-index>
    <actor-role-create
      :ams_template="actor_role_template"
      @takeAction="takeAction"
      @noAction="noAction"
      :reroute="false"
    />
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import WorkGroupUpdate from './WorkGroupUpdate.vue'
import CollectionCard from './CollectionCard.vue'
import ActorRoleIndex from './ActorRoleIndex.vue'
import ActorRoleCreate from './ActorRoleCreate.vue'
import {amsSchema} from '@/schema'

export default {
  components: {
    VuexBreadcrumb,
    WorkGroupUpdate,
    CollectionCard,
    ActorRoleIndex,
    ActorRoleCreate
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        work_group_ids: this.id
      },
      attrs: {},
      actor_role_template: {
        type: 'actor-roles',
        description: undefined,
        attributes: {
          'work-group-id': this.id,
          'profile-id': undefined
        }        
      },
      refresh: false,
      description: undefined
    }
  },
  methods: {
    takeAction() {
      this.refresh = true
      this.setActorRole()
    },
    noAction() {
      this.setActorRole()
    },
    refreshed() {
      this.refresh = false
    },
    setActorRole() {
      let actor_role_ams = amsSchema.find( 
        el =>  
        el.type === 'actor-roles'
      )
      actor_role_ams.attributes['work-group-id'] = this.id
      this.actor_role_template = {...actor_role_ams}
    },
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.description = description
      this.setActorRole()
    }
  }
}
</script>